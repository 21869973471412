import React, { FC } from "react"

import { Icon, IconName } from "src/components/common/icon"
import { ContentfulRichText } from "src/components/contentful/components/ContentfulRichText"
import { create } from "src/helpers/bem"
import { ContentfulRichTextData } from "src/types/contentful/autoservice"

import styles from "./IconList.module.scss"
const bem = create(styles, "IconList")

export type IconColor = "default" | "base" | "success" | "error" | "emphasized"
export type IconBackground = "no-background" | "primary"
export type IconListItem = {
  title?: string
  icon?: IconName
  content?: ContentfulRichTextData
  color?: IconColor
  iconBackground?: IconBackground
}

export type IconListProps = {
  /** One item of the list */
  items: IconListItem[]
  defaultIcon?: IconName
  defaultIconColor?: IconColor
  defaultIconBackground?: IconBackground
}

type ModifiersProps = {
  finalBackground: IconBackground
  finalColor: IconColor
  hasBackground: boolean
}

const modifiers = (props?: ModifiersProps) => {
  if (!props) {
    return {
      "color-base": true,
      "iconBackground-no-background": true,
      hasBackground: false,
    }
  }

  const { finalBackground, finalColor, hasBackground } = props

  return {
    [`color-${finalColor}`]: true,
    [`iconBackground-${finalBackground}`]: hasBackground,
    [`hasBackground`]: hasBackground,
  }
}

/**
 * An embedded entry of Rich text that display a list with selected icons, more sophisticated than regular numeric and bullet point lists.
 *
 * It has multiple configuration choices.
 *
 * All the `default` properties can be used to select one icon, color, and background for the entire list.
 *
 * But, if any item on the list has its own property, it will override the default.
 */
export const IconList: FC<IconListProps> = ({
  items,
  defaultIcon = "checkmark",
  defaultIconColor = "default",
  defaultIconBackground = "no-background",
}) => {
  return (
    <ul className={bem(undefined, modifiers())}>
      {items.map(({ title, icon, content, color, iconBackground }, index) => {
        // Override the default properties if they are available
        const finalIcon = icon || defaultIcon

        const finalBackground = iconBackground || defaultIconBackground
        const hasBackground = finalBackground !== "no-background"

        // The color is always "base" if there's a background
        const finalColor = hasBackground ? "base" : color || defaultIconColor

        const listModifiers = modifiers({
          finalBackground,
          finalColor,
          hasBackground,
        })

        const iconComponent = (
          <Icon className={bem("icon", listModifiers)} name={finalIcon} />
        )

        return (
          <li className={bem("item", listModifiers)} key={`${title}-${index}`}>
            {hasBackground ? (
              <span className={bem(`iconBackground-${finalBackground}`)}>
                {iconComponent}
              </span>
            ) : (
              iconComponent
            )}
            <div className={bem("content", listModifiers)}>
              <ContentfulRichText
                className={bem("content", listModifiers)}
                content={content}
                options={{
                  isList: true,
                }}
              />
            </div>
          </li>
        )
      })}
    </ul>
  )
}
